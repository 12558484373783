<script>
import Base from '#/v-shop-base-layout/vue-src/v-shop/comp_CategoriesMegaMenu.vue'
export default {
	extends: Base,
	computed: {
		cSubmenuStyles() {
			let baseStyles = `height: auto; min-height: 100%; max-height: ${this.cMaxHeight}px;`
			if (this.$srv('AppBarConfig.type') === 'round') {
				if (this.$srv('AppBarConfig.roundAppBarMenuLocation') === 'before') {
					return `${baseStyles} width: 952px`
				} else {
					return `${baseStyles} width: 776px`
				}
			} else {
				if (this.$ssr) return
				const offset = 70

				const maxWidth = 835
				const calcWidth = this.windowWidth - this.categoriesWidth - offset
				const width = calcWidth > maxWidth ? maxWidth : calcWidth
				return `${baseStyles} width: ${width}px; left: 100%;`
			}
		},
	},
}
</script>
