<script>
import BaseAppBarTop from '#/v-shop-base-layout/vue-src/v-shop/comp_AppBar-Top.vue'
export default {
	extends: BaseAppBarTop,
	computed: {
		mobileSearchClass() {
			return 'primary'
		},
		containerStyle() {
			return 'max-width: 1222px'
		},
	},
}
</script>
