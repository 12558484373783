<script>
import BaseProductPageDescription from '#/v-shop-base-layout/vue-src/v-shop/comp_ProductPage-Description.vue'

export default {
	extends: BaseProductPageDescription,
	data() {
		return {
			descriptionOpen: false,
			maxDescriptionHeight: '12em',
		}
	},
	computed: {
		cDescStyle() {
			return {
				'max-height': this.descriptionOpen ? 'unset' : this.maxDescriptionHeight,
				overflow: this.descriptionOpen ? 'auto' : 'hidden',
			}
		},
		btnText() {
			return this.descriptionOpen ? 'MENOS' : 'MÁS'
		},
	},
}
</script>

<template>
	<div class="product-page__description" v-if="hasDescription">
		<h2 class="header font-3 font-weight-bold pb-4 product-page__description-title">Descripción</h2>
		<div :style="cDescStyle">
			<div v-html="htmlDescription" v-if="hasHtml"></div>
			<div class="product-page__description-content" v-else>
				{{ textDescription }}
			</div>
		</div>
		<div
			v-if="maxDescriptionHeight"
			class="link--text pa-3 text-center"
			@click="descriptionOpen = !descriptionOpen"
		>
			<span class="pointer font-0">LEER {{ btnText }}</span>
		</div>
	</div>
</template>
