<script>
import BaseAutocompleteSearchField from '@/v-shop/comp_AutocompleteSearchField.vue'

export default {
	extends: BaseAutocompleteSearchField,
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ac-search-field {
	width: 100%;
	max-width: 860px;
	height: 46px !important;

	::v-deep .input-container {
		border-radius: 23px;
		border: none;
		input {
			padding-left: 20px !important;
			font-size: 1.1rem;
		}
		@media #{map-get($display-breakpoints, 'xs-only')} {
			background-color: var(--primary) !important;
			border: 1px solid var(--primarytext) !important;
			input {
				color: var(--primarytext);
			}
			input::placeholder {
				color: var(--primarytext);
				opacity: 0.6;
			}
		}
	}

	::v-deep .icon-container {
		background-color: var(--primary);
		.v-icon {
			color: var(--primarytext);
			padding: 0 4px !important;
			font-size: 24px !important;
		}
	}
}
</style>

