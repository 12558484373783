<script>
import bgMobile from '../assets/cybermonday/fondo-mobile.jpg'
import bgDesktop from '../assets/cybermonday/fondo-desktop.jpg'
import cyberMobile from '../assets/cybermonday/cyber-title-mobile.png'
import cyberDesktop from '../assets/cybermonday/cyber-title-desktop.png'
import logoSelmaMobile from '../assets/cybermonday/logo-selma-mobile.png'
import logosDesktop from '../assets/cybermonday/logos-desktop.png'
import discount from '../assets/cybermonday/50off.png'
import produsGrande from '../assets/cybermonday/produs-grande.png'

import countdownMixin from '../mixins/countdown-mixin'

export default {
	mixins: [countdownMixin('cybermonday/secondsLeft')],
	metaInfo() {
		return {
			title: 'Cybermonday',
			meta: [{ vmid: 'cy', name: 'description', content: 'Cybermonday' }],
		}
	},
	data() {
		return {
			// images
			bgMobile,
			bgDesktop,
			cyberMobile,
			cyberDesktop,
			logoSelmaMobile,
			logosDesktop,
			discount,
			produsGrande,

			// form
			firstname: '',
			email: '',
			lastname: '',
			birthdate: '',
			activePicker: null,
			modal: false,
			validation: {},
			state: 'pending',
			loading: false,
		}
	},
	methods: {
		async submit() {
			const data = {
				firstname: this.firstname,
				email: this.email,
				lastname: this.lastname,
				birthdate: this.birthdate,
			}

			return await this.$shopApi.post({
				url: '/user-forms/cybermonday',
				data: { data },
				loading: (v) => {
					this.loading = v
				},
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	watch: {
		secondsLeft() {
			if (!this.secondsLeft) return this.$router.replace({ name: 'cybermonday' })
		},
	},
	mounted() {
		if (!this.secondsLeft) return this.$router.replace({ name: 'cybermonday' })
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div>
		<div
			:class="$b.d ? 'container--desktop' : $b.t ? 'container--tablet' : ''"
			:style="`background-image: url(${
				$b.mt ? bgMobile : bgDesktop
			}); background-repeat: round; height: 100%`"
		>
			<div class="py-3">
				<div class="text-center">
					<router-link
						:to="{ name: 'home' }"
						class="d-inline-block"
						:style="$b.m ? 'width: 68px' : 'width: 88px'"
					>
						<Media aspectRatio="1" :src="logoSelmaMobile" />
					</router-link>
				</div>
				<div class="d-flex justify-center pt-3">
					<Media
						:aspectRatio="$b.m ? 634 / 283 : 637 / 77"
						:src="$b.m ? cyberMobile : cyberDesktop"
						:width="$b.m ? '60%' : '45%'"
					/>
				</div>
				<div class="dates-message color-purple text-center poppins" :class="$b.m ? 'font-2' : 'font-3'">
					6, 7 y 8 DE NOVIEMBRE
				</div>
				<div class="timer d-flex justify-center pt-4" :class="$bem('timer')">
					<div
						class="d-flex white--text align-center poppins font-weight-bold"
						:class="[$bem('__container'), $b.d && $bem('__container--desktop')]"
					>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ days }}</div>
							<div :class="$bem('__unit')">DIAS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ hours }}</div>
							<div :class="$bem('__unit')">HORAS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ minutes }}</div>
							<div :class="$bem('__unit')">MINUTOS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ seconds }}</div>
							<div :class="$bem('__unit')">SEGUNDOS</div>
						</div>
					</div>
				</div>
				<div class="color-violet poppins font-2 px-4 py-8 pb-4 text-center">
					¡Enterate de nuestros <br />
					super descuentos antes que nadie!
				</div>
				<div :class="$bem('form')" v-if="state !== 'success'">
					<div
						:class="[
							$b.m && $bem('__container'),
							$b.t && $bem('__container--tablet'),
							$b.d && $bem('__container--desktop'),
						]"
						class="mx-auto"
					>
						<form @submit.prevent="submit">
							<v-row class="mb-4">
								<v-col cols="12" sm="6" md="6" class="py-1">
									<TextField class="py-3" v-model="firstname" :error-messages="validation.firstname">
										<template #label>
											<div class="d-flex justify-space-between w100">
												<div>Nombre</div>
												<div class="font-0">*Campos Obligatorios</div>
											</div>
										</template>
									</TextField>
								</v-col>
								<v-col cols="12" sm="6" md="6" class="py-1">
									<TextField
										class="py-3"
										v-model="email"
										label="Mail*"
										:error-messages="validation.email"
									/>
								</v-col>
								<v-col cols="12" sm="6" md="6" class="py-1">
									<TextField
										class="py-3"
										v-model="lastname"
										label="Apellido*"
										:error-messages="validation.lastname"
									/>
								</v-col>
								<v-col cols="12" sm="6" md="6" class="py-1">
									<TextField
										class="py-3"
										v-model="birthdate"
										label="Cumpleaños*"
										type="date"
										:error-messages="validation.birthdate"
									/>
								</v-col>
							</v-row>
							<div class="d-flex justify-center pt-8 pb-4">
								<Button
									color="#22FEFE"
									style="color: #a007cb !important; border-radius: 10px"
									:class="$bem('__button')"
									depressed
									type="submit"
									:height="$b.m ? '50px' : '65px'"
									:loading="loading"
								>
									SUSCRIBIRSE
								</Button>
							</div>
						</form>
					</div>
				</div>
				<div v-else>
					<div class="color-violet poppins font-2 px-4 pb-4 text-center">
						<div class="pt-4 pb-6 font-5">Gracias por Suscribirte</div>
						<div>Pronto te enviaremos todas nuestras ofertas</div>
						<router-link :to="$shop.getShopRoute()">
							<Button
								class="mt-4"
								color="#22FEFE"
								style="color: #a007cb !important; border-radius: 10px"
								depressed
								:height="$b.m ? '50px' : '65px'"
							>
								Volver a la tienda
							</Button>
						</router-link>
					</div>
				</div>
				<div class="d-flex justify-center align-center" :class="{ 'flex-column': $b.m }">
					<Media
						:src="discount"
						:aspectRatio="332 / 256"
						:width="$b.m ? '70%' : $b.t ? '25%' : '350px'"
					/>
					<Media
						:src="produsGrande"
						:aspectRatio="1066 / 888"
						:width="$b.m ? '70%' : $b.t ? '25%' : '350px'"
					/>
				</div>
				<div v-if="!$b.m" class="d-flex justify-center">
					<Media :src="logosDesktop" :aspectRatio="896 / 144" :width="$b.t ? '70%' : '60%'" />
				</div>
			</div>
			<div
				class="text-container text-center mb-8"
				:class="
					$b.m ? 'text-container--mobile' : $b.t ? 'text-container--tablet' : 'text-container--desktop'
				"
			>
				<div class="mb-3">CYBER MONDAY 2023</div>
				<div>
					Conocé las ofertas en dermocosmética, cuidado personal y suplementos Cyber Monday 2023 Selma te
					trae el mejor catálogo de ofertas en dermocosmética, cuidado personal, fragancias, suplementos
					y electro Cyber Monday 2023. Por eso, si estás buscando la sintonía perfecta entre productos de
					belleza y cuidado, nuestras ofertas en dermocosmética Cyber Monday 2023 son una oportunidad que
					no podés dejar pasar. Cyber Monday es la campaña de ventas online organizada por CACE (Cámara
					Argentina de Comercio Electrónico) que brinda increíbles ofertas y descuentos en las
					principales tiendas online de Argentina y que se desarrolla del 6 al 8 de noviembre. En esta
					edición, tenemos una gran variedad de cremas y fragancias para que puedas comprar en línea
					todos los productos que siempre deseaste para vos.
				</div>
				<div class="my-3">SUSCRIBITE</div>
				<div>
					Enterate antes que nadie las ofertas y los productos disponibles. Conseguí beneficios
					exclusivos para el próximo Cyber Monday en Selma Digital.
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Poppins:wght@500;700&display=swap');

.text-container {
	margin: auto;
	&--mobile {
		padding: 0 16px 0 16px;
	}
	&--tablet {
		max-width: 80%;
	}
	&--desktop {
		max-width: 55%;
	}
}

.color-purple {
	color: #8f3ece;
}

.color-violet {
	color: #8500b4;
}

.dates-message {
	letter-spacing: 2px;
}

.container {
	&--tablet {
		height: 100vh;
	}
	&--desktop {
		background-size: cover;
	}
}

::v-deep .theme--light.v-input input {
	color: rgba(0, 0, 0, 0.62) !important;
}

.timer {
	&__container {
		border: 2px solid #20fcf4;
		border-radius: 15px;

		&--desktop {
			width: 100%;
			max-width: 420px;
			justify-content: space-around;
		}

		&__time-unit-container {
		}
	}
	&__digits {
		font-size: 3.2rem;
		line-height: 3.1rem;
	}
	&__unit {
		font-size: 0.9rem;
	}
}

.form {
	&__container {
		max-width: 265px;

		&--tablet {
			max-width: 506px;
		}

		&--desktop {
			max-width: 35%;
		}

		&__button {
			font-size: 1.3rem;
		}
	}
}

::v-deep .v-btn__content {
	font-size: 1.3rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: 800;

	@media (min-width: 900px) {
		font-size: 1.6rem;
	}
}

::v-deep .v-text-field__suffix {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-label {
	width: 100% !important;
	max-width: unset !important;
}
.montserrat {
	font-family: 'Montserrat', sans-serif;
}

.poppins {
	font-family: 'Poppins', sans-serif;
}
</style>
